<template>
  <div class="pt-apple-calendar" :class="{ 'pt-apple-calendar--dense': dense }">
    <span class="pt-apple-calendar__month" :class="{ 'pt-apple-calendar__month--dense': dense }">
      {{ dateWithAbbreviationMonthOnly(date) }}
    </span>
    <span class="pt-apple-calendar__day" :class="{ 'pt-apple-calendar__day--dense': dense }">
      {{ dateWithDayOnly(date) }}
    </span>
    <span class="pt-apple-calendar__hour" :class="{ 'pt-apple-calendar__hour--dense': dense }">
      {{ timeWithHours(hour) }}
    </span>
  </div>
</template>

<script>
import {
  dateWithAbbreviationMonthOnly,
  dateWithDayOnly,
  timeWithHours
} from '~/utils/helpers/date';

export default {
  name: 'AppleCalendar',
  props: {
    date: {
      type: String,
      default: ''
    },
    hour: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dateWithAbbreviationMonthOnly,
    dateWithDayOnly,
    timeWithHours
  })
};
</script>
<style lang="sass " src="./style.scss" />
