<template>
  <div class="w-full h-full">
    <h2 v-if="titleBanner" class="block ml-4 my-2 font-ubuntu text-[16px] font-medium text-gray">
      {{ titleBanner }}
    </h2>
    <article class="pt-calendar" :class="`pt-calendar-${theme} pt-calendar__${type}`">
      <div
        v-if="type !== 'calendarList' && paginator && list.length > maxEvents"
        class="pt-calendar__header"
      >
        <div class="pt-calendar__header--paginator">
          <span
            class="paginator__previous"
            :class="{ active: paginatorState.previous }"
            @click="changeEvents('-')"
            v-text="`< ${$t('formation.courses.calendar.actions.previous')}`"
          ></span>
          <span
            class="paginator__next"
            :class="{ active: paginatorState.next }"
            @click="changeEvents('+')"
            v-text="`${$t('formation.courses.calendar.actions.next')} >`"
          ></span>
        </div>
      </div>
      <nuxt-link v-if="link?.url" class="link" :to="link.url">
        {{
          items.length > 0 || !enabledEmptyMessage
            ? $t('ui.label.showOurCalendar')
            : $t('ui.label.showMoreEvents')
        }}
        <icon name="pt-arrow-right" class="pt-events-menu__icon-see-more" />
      </nuxt-link>
      <ul v-if="type === 'sessionList'">
        <li v-for="item in items" :key="item.slug" :class="{ 'pt-calendar__dense': dense }">
          <div v-if="item.lectures" class="flex align-middle content-center">
            <Labels label-type="gray" :items="dateToStringWithNameMonth(item.date)" />
            <span
              v-if="
                item.lectures.length > 0 &&
                item.lectures[0].event_time &&
                item.lectures[item.lectures.length - 1].event_time &&
                isSameOrBefore(item.date)
              "
              class="font-sans text-sm font-semibold text-gray"
            >
              {{
                timeWithHours(item.lectures[0].event_time) +
                ' - ' +
                timeWithHours(item.lectures[item.lectures.length - 1].event_time)
              }}
            </span>
          </div>
          <div class="pt-calendar__content">
            <h2 class="pt-calendar__description" :class="`pt-calendar-${theme}__description`">
              {{ item.title }}
            </h2>
          </div>
        </li>
      </ul>
      <ul v-else-if="type === 'noDate'">
        <li :class="{ 'pt-calendar__dense': dense }">
          <Labels
            label-type="text-gray"
            :items="$t('formation.detail.session_onlinecourse_duration')"
          />
        </li>
      </ul>
      <ul v-else-if="type === 'schedulerList' || type === 'calendarList'">
        <template v-if="loading">
          <SkeletonCalendar
            v-for="(item, index) in 3"
            :key="index"
            :class="[
              { 'pt-calendar__dense': dense },
              {
                'pt-calendar__border': index !== 3 - 1
              }
            ]"
          />
        </template>

        <template v-if="!loading && items.length > 0">
          <li
            v-for="(item, index) in items"
            :key="index"
            :class="[
              { 'pt-calendar__dense': dense },
              {
                'pt-calendar__border': index !== items.length - 1
              }
            ]"
          >
            <div
              v-if="type === 'schedulerList'"
              class="bg-white flex flex-col relative overflow-hidden rounded"
              :class="dense ? 'h-[60px] w-[60px]' : 'h-[90px] w-[90px]'"
            >
              <span
                class="text-white text-xs bg-red flex justify-center items-center font-bold uppercase"
                :class="dense ? 'h-[15px]' : 'h-[20px]'"
              >
                {{ dateWithAbbreviationMonthOnly(item.event_date || item.item.event_date) }}
              </span>
              <span
                class="text-gray-dark flex justify-center items-center font-bold"
                :class="dense ? 'h-[20px] text-2xl mt-1' : 'text-4xl mt-auto'"
              >
                {{ dateWithDayOnly(item.event_date || item.item.event_date) }}
              </span>
              <span
                class="text-gray-dark flex justify-center items-center font-semibold mt-auto"
                :class="dense ? 'text-xs' : 'text-base'"
              >
                {{
                  item.min_session_time ||
                  timeWithHoursAndMinutes(item.event_date || item.item.event_date)
                }}
                h
              </span>
            </div>
            <div :class="{ 'p-4': type === 'calendarList' }" class="pt-calendar__content">
              <div v-if="item.event" class="pt-calendar__detail">
                <Labels
                  v-if="item.event?.event_features?.event_format"
                  :items="firstLetterUppercase(item.event.event_features.event_format)"
                  label-type="gray"
                />
              </div>
              <h3 class="pt-calendar__description" :class="`pt-calendar-${theme}__description`">
                <nuxt-link :to="createLink(item)">
                  {{ item.title || item.item.title }}
                </nuxt-link>
              </h3>
              <div
                v-if="type === 'calendarList' && item.list_of_sessions[0].lectures?.length"
                class="mt-1 text-gray-light font-semibold text-sm"
              >
                {{
                  timeWithHours(item.list_of_sessions[0].lectures[0].event_time) +
                  ' - ' +
                  timeWithHours(
                    item.list_of_sessions[0].lectures[item.list_of_sessions[0].lectures.length - 1]
                      .event_time
                  )
                }}
              </div>
              <div
                v-if="item.event?.campus && showLocation"
                class="flex space-x-1 text-sm text-gray-light mt-1"
              >
                <icon name="pt-map-marker" class="text-lg" />
                <span>{{ item.event.campus.name }}</span>
              </div>
            </div>
          </li>
        </template>
        <div
          v-else-if="!loading && enabledEmptyMessage"
          class="flex flex-col items-center justify-center text-center space-y-4"
        >
          <icon class="text-[33px] text-blue" name="pt-calendar-slash" />
          <span class="font-ubuntu font-medium text-lg">
            {{ emptyMessage }}
          </span>
        </div>
      </ul>
      <ul v-else>
        <li v-for="(item, index) in items" :key="index">
          <div class="flex flex-col">
            <div class="pt-calendar__image">
              <div class="absolute top-1 left-1">
                <WidgetsAppleCalendar
                  v-if="item.event_date || (item && item.item)"
                  dense
                  :date="item.session.date || item.event_date || item.item.event_date"
                  :hour="
                    item.session.time ||
                    item.session.min_session_time ||
                    item.event_date ||
                    item.item.event_date
                  "
                />
              </div>
              <nuxt-link :to="createLink(item)">
                <NuxtImg
                  :key="indexEvents + index"
                  loading="lazy"
                  :src="item.main_image.url"
                  :alt="item.title"
                />
              </nuxt-link>
            </div>
            <LabelsPrice
              v-if="item.event_features.price && item.event_features.price !== 0"
              class="mt-3"
              :price="item.event_features.price"
              :discount="item.event_features.discount"
              :currency="item.event_features.currency"
              icon
              theme="orange"
            />
          </div>
          <div class="pt-calendar__upcoming-content">
            <div class="pt-calendar__description" :class="`pt-calendar-${theme}__description`">
              <Labels
                v-if="showTypeEvent && item.event_features?.event_format"
                label-type="gray"
                :items="uppercase($t(`strapiDataMap.${item.event_features.event_format}`))"
              />
              <nuxt-link :to="createLink(item)">
                {{ item.title || item.item.title }}
              </nuxt-link>
            </div>
            <p v-if="item.abstract" class="pt-calendar__absctract mt-3">
              <nuxt-link :to="createLink(item)">
                {{ item.abstract }}
              </nuxt-link>
            </p>
            <div class="pt-calendar__divider">
              <span
                v-for="tag in item.tags"
                :key="tag.slug"
                class="pt-calendar__label"
                @click="clickGoToSearch"
                v-text="tag.label"
              />
            </div>
          </div>
        </li>
      </ul>
    </article>
  </div>
</template>
<script>
/* eslint-disable max-lines */
import {
  dateWithAbbreviationMonthOnly,
  dateToStringWithNameMonth,
  dateWithDayOnly,
  timeWithHoursAndMinutes,
  timeWithHours,
  isSameOrBefore
} from '@/utils/helpers/date';
import FavoriteEntities from '~/mixins/FavoriteEntities';
import { COURSE_BASE_URL, SEARCH_RESULTS_URL } from '~/utils/enums';
import { firstLetterUppercase, uppercase, capitalize } from '~/utils/helpers/string';

export default {
  name: 'Calendar',
  mixins: [FavoriteEntities],
  props: {
    list: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    titleBanner: {
      type: String,
      default: ''
    },
    link: {
      type: Object,
      default: () => ({})
    },
    dense: {
      type: Boolean,
      default: false
    },
    paginator: {
      type: Boolean,
      default: false
    },
    showTypeEvent: {
      type: Boolean,
      default: false
    },
    showLocation: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light',
      validator: val => ['dark', 'light', 'transparent'].includes(val)
    },
    enabledEmptyMessage: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'schedulerList',
      validator: val =>
        ['schedulerList', 'calendarList', 'sessionList', 'noDate', 'upcomingList'].includes(val)
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dateWithAbbreviationMonthOnly,
    dateWithDayOnly,
    timeWithHoursAndMinutes,
    dateToStringWithNameMonth,
    timeWithHours,
    isSameOrBefore,
    firstLetterUppercase,
    uppercase,
    indexEvents: 0,
    maxEvents: 4,
    paginatorState: {
      next: true,
      previous: false
    }
  }),
  computed: {
    items() {
      return this.list.slice(this.indexEvents, this.indexEvents + this.maxEvents);
    },
    emptyMessage() {
      const sector = this.$route.query.sector;
      const subsector = this.$route.query.subsector;

      if (sector) {
        return `${this.$t('formation.courses.calendar.emptySector', {
          value: `${this.$t('formation.courses.calendar.sector')} ${capitalize(
            sector.replace(/-/g, ' ')
          )}`
        })}`;
      } else if (subsector) {
        return `${this.$t('formation.courses.calendar.emptySector', {
          value: `${this.$t('formation.courses.calendar.subsector')} ${capitalize(
            subsector.replace(/-/g, ' ')
          )}`
        })}`;
      } else {
        return this.$t('formation.courses.calendar.empty');
      }
    }
  },
  watch: {
    indexEvents() {
      if (this.indexEvents > 0 && this.indexEvents + this.maxEvents < this.list.length) {
        this.paginatorState.next = true;
        this.paginatorState.previous = true;
      } else if (this.indexEvents === 0) {
        this.paginatorState.next = true;
        this.paginatorState.previous = false;
      } else if (this.indexEvents + this.maxEvents >= this.list.length) {
        this.paginatorState.previous = true;
        this.paginatorState.next = false;
      }
    }
  },
  methods: {
    createLink(item) {
      try {
        return item.article
          ? `${item.article.category.section}/${item.article.slug}`
          : item
            ? `${COURSE_BASE_URL}/${item.slug || item.event.slug}`
            : '';
      } catch {
        return '';
      }
    },
    changeEvents(operation) {
      if (operation === '+' && this.indexEvents + this.maxEvents < this.list.length) {
        this.indexEvents += this.maxEvents;
      } else if (operation === '-' && this.indexEvents > 0) {
        this.indexEvents -= this.maxEvents;
      }
    },
    clickGoToSearch(e) {
      e.preventDefault();
      const eventText = [
        e.target.textContent.toLowerCase(),
        e.target.innerHTML.toLowerCase(),
        e.target.outerText.toLowerCase()
      ];
      this.$store.commit('search/setFilter', eventText);
      this.$store.commit('search/setFilterAttribute', undefined);
      this.$router.push(SEARCH_RESULTS_URL);
    }
  }
};
</script>
<style src="./style.scss" lang="scss"></style>
