<template>
  <div class="w-full flex animate-pulse">
    <div class="h-[60px] min-w-[51px] bg-gray-soft mb-4 rounded">
      <div class="w-full h-4 bg-gray rounded-t" />
    </div>
    <div class="ml-3 w-full">
      <div class="h-5 w-16 bg-gray rounded" />
      <div class="h-3 bg-gray rounded-full my-2 w-full" />
      <div class="h-3 bg-gray rounded-full w-2/3" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PtSkeletonCalendarCard'
};
</script>
